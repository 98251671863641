<template>
  <Layout>
    <PageHeader :title="title" />
    <div class="row" v-if="getBookIngItemSelected">
      <div class="col-xl-12">
        <div>
          <button
            type="button"
            class="btn btn-success mb-3"
            @click="onExportPDF(getBookIngItemSelected)"
          >
            <i class="mdi mdi-file-pdf-outline me-1"></i>
            ออกเอกสารแบบขอใช้พื้นที่
          </button>
        </div>
        <div class="card col-xl-3">
          <div class="p-3" style="text-align: center;">
            <!-- <span
              v-if="getBookIngItemSelected.status === 'ยังไม่ดำเนินการ'"
              style="color: #BC1C1C;"
              >ยังไม่ดำเนินการ</span
            >
            <span
              v-if="
                getBookIngItemSelected.status === 'อยู่ระหว่างการตรวจสอบ' ||
                  getBookIngItemSelected.status === 'รอการแก้ไขพื้นที่'
              "
              style="color: #F4BC00;"
              >{{ getBookIngItemSelected.status }}</span
            >
            <span
              v-if="getBookIngItemSelected.status === 'สำเร็จ'"
              style="color: #65B52A;"
              >สำเร็จ</span
            >
            <span
              v-if="getBookIngItemSelected.status === 'ยกเลิก'"
              style="color: #DDDDDD;"
              >ยกเลิก</span
            > -->
            <span>{{ getBookIngItemSelected.status }}</span>
          </div>
        </div>
        <div class="custom-accordion">
          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark"
              data-toggle="collapse"
              v-b-toggle.checkout-billinginfo-collapse
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <i class="uil uil-receipt text-primary h2"></i>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">
                      รายละเอียดผู้ขอใช้พื้นที่
                    </h5>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>
            <b-collapse id="checkout-billinginfo-collapse" visible v-if="getBookIngItemSelected.applicantType != 'INTERNAL'">
              <div class="p-4 border-top">
                <form>
                  <div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group mb-4">
                          <label for="billing-name"
                            >ประเภทผู้ขอใช้ :
                            {{
                              getApplicantType(getBookIngItemSelected)
                            }}</label
                          >
                        </div>
                      </div>

                      <div
                        class="col-lg-6"
                        v-if="getBookIngItemSelected.agencyType"
                      >
                        <div class="form-group mb-4">
                          <label for="billing-name">ประเภทหน่วยงาน</label>
                          <input
                            readonly
                            type="text"
                            class="form-control"
                            id="billing-name"
                            placeholder="Enter name"
                            :value="getBookIngItemSelected.agencyType"
                          />
                        </div>
                      </div>
                      <div
                        class="col-lg-6"
                        v-if="getBookIngItemSelected.thOrgName"
                      >
                        <div class="form-group mb-4">
                          <label for="billing-email-address"
                            >ชื่อองค์กร/บริษัท (ภาษาไทย)</label
                          >
                          <input
                            readonly
                            type="text"
                            class="form-control"
                            :value="getBookIngItemSelected.thOrgName"
                          />
                        </div>
                      </div>

                      <div
                        class="col-lg-6"
                        v-if="getBookIngItemSelected.enOrgName"
                      >
                        <div class="form-group mb-4">
                          <label for="billing-phone"
                            >ชื่อองค์กร/บริษัท (ภาษาอังกฤษ)</label
                          >
                          <input
                            readonly
                            type="text"
                            class="form-control"
                            :value="getBookIngItemSelected.enOrgName"
                          />
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group mb-4">
                          <label for="billing-address"
                            >ชื่อ - นามสกุล (ภาษาไทย)</label
                          >
                          <input
                            readonly
                            type="text"
                            class="form-control"
                            :value="getBookIngItemSelected.thname"
                          />
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group mb-4">
                          <label for="billing-address"
                            >ชื่อ - นามสกุล (ภาษาอังกฤษ)</label
                          >
                          <input
                            readonly
                            type="text"
                            class="form-control"
                            :value="getBookIngItemSelected.enname"
                          />
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group mb-4">
                          <label for="billing-address">อีเมลล</label>
                          <input
                            readonly
                            type="text"
                            class="form-control"
                            :value="getBookIngEmailOwner"
                          />
                        </div>
                      </div>

                      <h4>ที่อยู่</h4>
                      <div class="col-lg-6">
                        <div class="form-group mb-4">
                          <label for="billing-address">บ้านเลขที่</label>
                          <input
                            readonly
                            type="text"
                            class="form-control"
                            :value="getBookIngItemSelected.address"
                          />
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group mb-4">
                          <label for="billing-address">แขวง / ตำบล</label>
                          <input
                            readonly
                            type="text"
                            class="form-control"
                            :value="getBookIngItemSelected.subdistrict"
                          />
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group mb-4">
                          <label for="billing-address">เขต / อำเภอ</label>
                          <input
                            readonly
                            type="text"
                            class="form-control"
                            :value="getBookIngItemSelected.district"
                          />
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group mb-4">
                          <label for="billing-address">จังหวัด</label>
                          <input
                            readonly
                            type="text"
                            class="form-control"
                            :value="getBookIngItemSelected.province"
                          />
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group mb-4">
                          <label for="billing-address">รหัสไปรษณีย์</label>
                          <input
                            readonly
                            type="text"
                            class="form-control"
                            :value="getBookIngItemSelected.zipcode"
                          />
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group mb-4">
                          <label for="billing-address">เบอร์โทรติดต่อ</label>
                          <label for="billing-address">รหัสไปรษณีย์</label>
                          <input
                            readonly
                            type="text"
                            class="form-control"
                            :value="getBookIngItemSelected.mobile"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </b-collapse>
            <b-collapse id="checkout-billinginfo-collapse" visible v-if="getBookIngItemSelected.applicantType == 'INTERNAL'">
              <div class="p-4 border-top">
                <form>
                  <div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group mb-4">
                          <label for="billing-name"
                            >ประเภทผู้ขอใช้ :
                            {{
                              getApplicantType(getBookIngItemSelected)
                            }}</label
                          >
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group mb-4">
                          <label for="billing-address"
                            >หน่วยงาน</label
                          >
                          <input
                            readonly
                            type="text"
                            class="form-control"
                            :value="getBookIngItemSelected.department_name"
                          />
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group mb-4">
                          <label for="billing-address"
                            >กลุ่มงาน</label
                          >
                          <input
                            readonly
                            type="text"
                            class="form-control"
                            :value="getBookIngItemSelected.group_name"
                          />
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group mb-4">
                          <label for="billing-address">เจ้าหน้าที่รับผิดชอบ</label>
                          <input
                            readonly
                            type="text"
                            class="form-control"
                            :value="getBookIngItemSelected.thname"
                          />
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group mb-4">
                          <label for="billing-address">หมายเลขโทรศัพท์ภายใน</label>
                          <input
                            readonly
                            type="text"
                            class="form-control"
                            :value="getBookIngItemSelected.tel"
                          />
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group mb-4">
                          <label for="billing-address">หมายเลขโทรศัพท์เคลื่อนที่</label>
                          <input
                            readonly
                            type="text"
                            class="form-control"
                            :value="getBookIngItemSelected.mobile"
                          />
                        </div>
                      </div>

                      <h4>ข้อมูลการจัดนิทรรศการ</h4>
                      <div class="col-xs-12">
                        <div class="form-group mb-4">
                          <label for="billing-address">ชื่องาน</label>
                          <input
                            readonly
                            type="text"
                            class="form-control"
                            :value="getBookIngItemSelected.activities"
                          />
                        </div>
                      </div>

                      <div class="col-xs-12">
                        <div class="form-group mb-4">
                          <label for="billing-address">ประเภทของงาน / รายละเอียด</label>
                          <textarea
                            readonly
                            rows="5"
                            class="form-control"
                            :value="getBookIngItemSelected.activities_detail"
                          ></textarea>
                        </div>
                      </div>

                      <div class="col-lg-4">
                        <div class="form-group mb-4">
                          <label for="billing-address">วันที่เริ่มติดตั้ง</label>
                          <input
                            readonly
                            type="text"
                            class="form-control"
                            :value="getFormatDate(getBookIngItemSelected.setup_start_date)"
                          />
                        </div>
                      </div>

                      <div class="col-lg-4">
                        <div class="form-group mb-4">
                          <label for="billing-address">เวลาเริ่มติดตั้ง</label>
                          <input
                            readonly
                            type="text"
                            class="form-control"
                            :value="getBookIngItemSelected.setup_start_time"
                          />
                        </div>
                      </div>

                      <div class="col-lg-4">
                        <div class="form-group mb-4">
                          <label for="billing-address">เวลาสิ้นสุดติดตั้ง</label>
                          <input
                            readonly
                            type="text"
                            class="form-control"
                            :value="getBookIngItemSelected.setup_end_time"
                          />
                        </div>
                      </div>

                      <div class="col-lg-4">
                        <div class="form-group mb-4">
                          <label for="billing-address">วันรื้อถอนสมบูรณ์</label>
                          <input
                            readonly
                            type="text"
                            class="form-control"
                            :value="getFormatDate(getBookIngItemSelected.remove_start_date)"
                          />
                        </div>
                      </div>

                      <div class="col-lg-4">
                        <div class="form-group mb-4">
                          <label for="billing-address">เวลาเริ่มรื้อถอน</label>
                          <input
                            readonly
                            type="text"
                            class="form-control"
                            :value="getBookIngItemSelected.remove_start_time"
                          />
                        </div>
                      </div>

                      <div class="col-lg-4">
                        <div class="form-group mb-4">
                          <label for="billing-address">เวลาสิ้นสุดรื้อถอน</label>
                          <input
                            readonly
                            type="text"
                            class="form-control"
                            :value="getBookIngItemSelected.remove_end_time"
                          />
                        </div>
                      </div>

                      <div class="col-xs-12">
                        <div class="form-group mb-4">
                          <label for="billing-address">อุปกรณ์ที่คาดว่าจะต้องใช้</label>
                          <textarea
                            readonly
                            rows="5"
                            class="form-control"
                            :value="getBookIngItemSelected.equipment"
                          ></textarea>
                        </div>
                      </div>

                      <div class="col-lg-4">
                        <div class="form-group mb-4">
                          <label for="billing-address">จำนวนที่จอดรถยนต์ (คาดการณ์)</label>
                          <input
                            readonly
                            type="text"
                            class="form-control"
                            :value="getBookIngItemSelected.parking"
                          />
                        </div>
                      </div>

                    </div>
                  </div>
                </form>
              </div>
            </b-collapse>
          </div>

          <div class="card" v-if="getBookIngItemSelected.applicantType != 'INTERNAL'">
            <a
              href="javascript: void(0);"
              class="collapsed text-dark"
              data-toggle="collapse"
              v-b-toggle.checkout-shippinginfo-collapse
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <i class="mdi mdi-attachment text-primary h2"></i>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">
                      เอกสารแนบการขอใช้พื้นที่
                    </h5>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse id="checkout-shippinginfo-collapse">
              <div class="p-4 border-top">
                <h5>รูปภาพตัวอย่างการจัดกิจกรรม</h5>
                <div class="row">
                  <div class="row">
                    <div
                      class="col-md-3 p-1"
                      v-for="value in getBookIngItemSelected.activityImageList"
                      :key="value"
                    >
                      <img
                        class="rounded me-2"
                        alt="200x200"
                        width="200"
                        :src="getFileURL(value)"
                        data-holder-rendered="true"
                      />
                    </div>
                  </div>
                  <div class="row mt-3">
                    <h5 style="padding-left: 0">เอกสารที่จำเป็น</h5>
                    <div
                      class="col-md-12 p-1"
                      v-for="item in getBookIngItemSelected.requireDoc"
                      :key="item.id"
                    >
                      <a :href="getFileURL(item.fileName)">{{ item.title }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>

          <div class="card">
            <a
              href="javascript: void(0);"
              class="collapsed text-dark"
              data-toggle="collapse"
              v-b-toggle.checkout-paymentinfo-collapse
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <i class="uil uil-bill text-primary h2"></i>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">รายละเอียดพื้นที่</h5>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse id="checkout-paymentinfo-collapse">
              <div
                class="p-4 border-top"
                v-if="getBookIngItemSelected !== null"
              >
                <div class="form-group">
                  <div class="form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      v-model="getBookIngItemSelected.isRequireAccessory"
                    />
                    <label class="form-check-label" for="formrow-customCheck"
                      >ต้องการอุปกรณ์เสริมภายในวันจัดงาน</label
                    >
                  </div>
                </div>
                <div
                  class="mt-4"
                  v-for="(item, index) of getBookIngItemSelected.bookingItems"
                  :key="item.id"
                >
                  <form class="needs-validation" @submit.prevent="onSubmit">
                    <div class="row">
                      <div class="media border-bottom pb-3">
                        <div class="me-4">
                          <img
                            :src="getFileURL(item.room.imageName)"
                            alt
                            class="avatar-lg"
                          />
                        </div>
                        <div
                          class="media-body align-self-center overflow-hidden"
                        >
                          <div>
                            <h5 class="text-truncate font-size-16">
                              <span>{{ item.room.name }}</span>
                            </h5>
                            <p class="mb-1">
                              {{ item.room.description }}
                            </p>
                            <p class="mb-1">
                              วันที่ขอใช้พื้นที่ :
                              <span class="fw-medium">{{
                                getFormatDate(item.startDate)
                              }}</span>
                              -
                              <span class="fw-medium">{{
                                getFormatDate(item.endDate)
                              }}</span>
                            </p>
                            <p class="mb-1">
                              จำนวน (วัน) :
                              <span class="fw-medium">{{
                                getDateDiff(item)
                              }}</span>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 mt-4">
                          <div class="form-group mb-3">
                            <h4 class="mb-3">
                              สำหรับ Admin ปรับเปลี่ยนข้อมูลการจองพื้นที่
                            </h4>
                            <label class="mb-3" for="validationCustom01"
                              >แก้ไขปรับเปลี่ยน พื้นที่</label
                            >
                            <multiselect
                              :options="getAreaListOption"
                              v-model="form[index].room"
                              class="helo"
                              track-by="name"
                              :allow-empty="false"
                              label="name"
                              @input="
                                event => onChangeAreaSelected(event, index)
                              "
                            >
                              <template
                                slot="singleLabel"
                                slot-scope="{ option }"
                              >
                                <span
                                  v-if="option.maintenance"
                                  style="color: red;"
                                  >พื้นที่ไม่พร้อมใช้งาน</span
                                >
                                <span>{{ option.name }}</span>
                              </template>
                            </multiselect>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 mt-4">
                        <div class="form-group mb-3">
                          <label>เวลาเริ่มต้น</label>
                          <date-picker
                            :not-before="disabledBefore"
                            :clearable="false"
                            v-model="form[index].startDate"
                            :first-day-of-week="1"
                            lang="th"
                            @change="onChangeDateSelected(index)"
                          ></date-picker>
                        </div>
                      </div>
                      <div class="col-md-4 mt-4">
                        <div class="form-group mb-3">
                          <label>เวลาสิ้นสุด</label>
                          <date-picker
                            :clearable="false"
                            v-model="form[index].endDate"
                            :first-day-of-week="1"
                            lang="th"
                            @change="onChangeDateSelected(index)"
                          ></date-picker>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 mt-3 mb-3">
                      <span v-if="form[index].isDuplicate" style="color: red;"
                        >พื้นที่และวันที่ เลือกไว้ ได้มีการพิจารณาอนุมัติแล้ว
                        โปรดเลือกวันที่ว่าง</span
                      >
                    </div>
                    <div class="mb-3 mb-0">
                      <div>
                        <button
                          @click="onResetForm(index)"
                          class="btn btn-primary ms-1"
                        >
                          ยกเลิกการแก้ไข
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="media-body align-self-center overflow-hidden mt-4" v-if="getBookIngItemSelected.applicantType != 'INTERNAL'">
                  <div>
                    <!-- <p class="mb-1">
                      ค่าประกันสัญญา :
                      {{ "\xa0" }}{{ "\xa0" }}{{ "\xa0" }}
                      <span class="fw-medium"
                        >{{
                          onDepositCalculateUpdate(
                            bookingDepositCalculate.toLocaleString()
                          )
                        }}{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0" }}บาท</span
                      >
                    </p>
                    <p class="mb-1">
                      ค่าเงินบำรุงรักษา :
                      {{ "\xa0" }}{{ "\xa0" }}{{ "\xa0"
                      }}<span class="fw-medium"
                        >{{
                          onMaintenanceCostCalculateUpdate(
                            bookingMaintenanceCostCalculate.toLocaleString()
                          )
                        }}{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0" }}บาท</span
                      >
                    </p>
                    <p>
                      ราคาทั้งหมด :
                      {{ "\xa0" }}{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0"
                      }}{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0"
                      }}{{ "\xa0"
                      }}<span class="fw-medium"
                        >{{
                          onSummaryPriceCalculateUpdate(
                            bookingSummaryPriceCalculate.toLocaleString()
                          )
                        }}{{ "\xa0" }}{{ "\xa0" }}{{ "\xa0" }}บาท</span
                      >
                    </p> -->
                    <table
                      class="table table-bordered mb-0"
                      v-if="
                        getDepositCalculateTemp !=
                          bookingDepositCalculate.toLocaleString() ||
                          getMaintenanceCostCalculateTemp !=
                            bookingMaintenanceCostCalculate.toLocaleString() ||
                          getSummaryPriceCalculateTemp !=
                            bookingSummaryPriceCalculate.toLocaleString()
                      "
                    >
                      <thead>
                        <tr>
                          <th></th>
                          <th>รายการค่าใช้จ่ายปัจจุบัน</th>
                          <th>แก้ไข</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>ค่าประกันสัญญา</td>
                          <td>{{ getDepositCalculateTemp }}</td>
                          <td>
                            {{
                              onDepositCalculateUpdate(
                                bookingDepositCalculate.toLocaleString()
                              )
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>ค่าเงินบำรุงรักษา</td>
                          <td>{{ getMaintenanceCostCalculateTemp }}</td>
                          <td>
                            {{
                              onMaintenanceCostCalculateUpdate(
                                bookingMaintenanceCostCalculate.toLocaleString()
                              )
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>ราคาทั้งหมด</td>
                          <td>{{ getSummaryPriceCalculateTemp }}</td>
                          <td>
                            {{
                              onSummaryPriceCalculateUpdate(
                                bookingSummaryPriceCalculate.toLocaleString()
                              )
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table
                      class="table table-bordered mb-0"
                      v-if="
                        getDepositCalculateTemp ==
                          bookingDepositCalculate.toLocaleString() &&
                          getMaintenanceCostCalculateTemp ==
                            bookingMaintenanceCostCalculate.toLocaleString() &&
                          getSummaryPriceCalculateTemp ==
                            bookingSummaryPriceCalculate.toLocaleString()
                      "
                    >
                      <tbody>
                        <tr>
                          <td>ค่าประกันสัญญา</td>
                          <td>{{ getDepositCalculateTemp }}</td>
                        </tr>
                        <tr>
                          <td>ค่าเงินบำรุงรักษา</td>
                          <td>{{ getMaintenanceCostCalculateTemp }}</td>
                        </tr>
                        <tr>
                          <td>ราคาทั้งหมด</td>
                          <td>{{ getSummaryPriceCalculateTemp }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>

        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">ปรับสถานะการจอง</h4>
              <div class="col-sm-5">
                <multiselect
                  v-model="bookingStatus"
                  :options="statusOptions"
                  class="helo"
                  :allow-empty="false"
                ></multiselect>
              </div>

              <div>
                <h4 class="card-title">หมายเหตุ</h4>
                <textarea
                  v-model="bookingRemark"
                  class="form-control"
                  rows="3"
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div>
                <h4 class="card-title">ชื่อผู้แก้ไข</h4>
                <input
                  type="text"
                  class="form-control"
                  v-model="changedBy"
                  @keydown="isChange = true"
                />
              </div>
              <div
                v-if="changedBy && isChange == false"
                style="margin-top: 15px;"
              >
                <h4 class="card-title">วันที่แก้ไข</h4>
                {{ updatedDate }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12" v-if="onGetHistory().length > 0">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">ประวัติการแก้ไข</h4>
              <table class="table table-bordered mb-0">
                <thead>
                  <tr>
                    <th>วันที่แก้ไข</th>
                    <th>ชื่อผู้แก้ไข</th>
                    <th>หมายเหตุ</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in onGetHistory()" :key="item.id">
                    <td>{{ item.booking.updated_date | moment }}</td>
                    <td>{{ item.booking.changedBy }}</td>
                    <td>{{ item.booking.remark }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="row my-4">
          <div class="col">
            <div class="text-sm-right mt-2 mt-sm-0">
              <button
                class="btn btn-primary ms-1"
                @click="onSubmitManageBooking"
              >
                <i class="uil uil-cog me-1"></i>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from "vuex";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import moment from "moment";
import _get from "lodash/get";
import _map from "lodash/map";
import _filter from "lodash/filter";
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker
  },
  filters: {
    moment: function(date) {
      if (date) {
        return moment(date).format("MMM DD, YYYY, HH:mm:ss");
      }
      return "";
    }
  },
  computed: {
    getBookIngItemSelected() {
      let obj = { ...this.$store.state.booking.bookingItemSelected };
      if (obj.bookingItems) {
        return obj;
      }
      return null;
    },
    getBookIngEmailOwner() {
      if (this.$store.state.booking.bookingOwner) {
        return this.$store.state.booking.bookingOwner;
      }
      return null;
    },
    getAreaListOption() {
      return this.$store.state.areaspecify.areaLists || [];
    },
    ...mapGetters("booking", {
      checkBookingOnAdminSelector: "checkBookingOnAdminSelector",
      bookingSummaryPriceCalculate: "bookingSummaryPriceCalculate",
      bookingMaintenanceCostCalculate: "bookingMaintenanceCostCalculate",
      bookingDepositCalculate: "bookingDepositCalculate",
      areaGetPricePerRoom: "areaGetPricePerRoom"
    }),
    ...mapGetters("app", {
      parseJwt: "parseJwt"
    }),
    getDepositCalculateTemp() {
      return this.$store.state.booking.depositCalculateTemp;
    },
    getSummaryPriceCalculateTemp() {
      return this.$store.state.booking.summaryPriceCalculateTemp;
    },
    getMaintenanceCostCalculateTemp() {
      return this.$store.state.booking.maintenanceCostCalculateTemp;
    }
  },
  created() {
    if (!this.$store.state.booking.bookingItemSelected) {
      this.$router.push("/bookingmanagement");
    }
    const filters = {};
    this.$store.dispatch("areaspecify/getAreaLists", filters);
  },
  page: {
    title: "Form Validation",
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ]
  },
  data() {
    return {
      text: new Date(),
      submitted: false,
      isChange: false,
      title: "จัดการ การขอใช้พื้นที่",
      bookingStatus:
        this.$store.state.booking.bookingItemSelected === null
          ? ""
          : this.$store.state.booking.bookingItemSelected.status,
      bookingRemark:
        this.$store.state.booking.bookingItemSelected === null
          ? ""
          : this.$store.state.booking.bookingItemSelected.remark,
      changedBy:
        this.$store.state.booking.bookingItemSelected === null
          ? ""
          : this.$store.state.booking.bookingItemSelected.changedBy,
      updatedDate:
        this.$store.state.booking.bookingItemSelected === null
          ? ""
          : moment(
              this.$store.state.booking.bookingItemSelected.updated_date
            ).format("MMM DD, YYYY, HH:mm:ss"),
      form:
        this.$store.state.booking.bookingItemSelected === null
          ? []
          : this.$store.state.booking.bookingItemSelected.bookingItems.map(
              o => {
                return {
                  startDate: new Date(o.startDate),
                  endDate: new Date(o.endDate),
                  room: { ...o.room },
                  isDuplicate: false
                };
              }
            ),
      statusOptions: [
        "ยังไม่ดำเนินการ",
        "อยู่ระหว่างการตรวจสอบ",
        "สำเร็จ",
        "ปรับเปลี่ยนพื้นที่",
        "ชำระเงินเรียบร้อย",
        "อนุญาต คำขอใช้พื้นที่",
        "ไม่อนุญาต คำขอใช้พื้นที่",
        "รอการชำระเงิน"
        // "ยกเลิก",
      ],
      typesubmit: false,
      disabledBefore: moment()
        .add(90, "days")
        .toISOString()
        .substr(0, 10),
      updateBookingItemObj: null
    };
  },
  methods: {
    onExportPDF(param) {
      this.$store.dispatch("exportpdf/exportFilePDF", {
        ...param,
        generateDate: new Date()
      });
    },
    getApplicantType(type) {
      if (type) {
        switch (_get(type, "applicantType", "")) {
          case "GUEST":
            return "บุคคลธรรมดา";
          case "GOVERNMENTAGENCY":
            return "หน่วยงานรัฐ/ราชการ";
          case "PRIVATEAGENCY":
            return "หน่วยงานเอกชน";
          case "INTERNAL":
            return "หน่วยงานภายใน";
        }
      }
      return "";
    },
    getFileURL(filename) {
      if (filename) {
        return `${process.env.VUE_APP_URL}/files/${filename}`;
      } else {
        return "";
      }
    },
    getFormatDate(date) {
      if (date) {
        let convertToDate = new Date(date);
        return new Date(
          convertToDate.getFullYear(),
          convertToDate.getMonth(),
          convertToDate.getDate()
        ).toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric"
        });
      } else {
        return "";
      }
    },
    getDateDiff(param) {
      let getStartDate = _get(param, "startDate", "");
      let getEndDate = _get(param, "endDate", "");
      const date1 = new Date(getStartDate);
      const date2 = new Date(getEndDate);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays + 1;
    },
    onSubmitManageBooking() {
      let currentState = this;
      let parseJwtResult = this.parseJwt;

      let getBookingItems =
        _get(this.getBookIngItemSelected, "bookingItems", []) || [];
      this.updateBookingItemObj = _map(getBookingItems, (o, index) => {
        let roomId = currentState.$store.state.booking.bookFormUpdate[index].id;
        let startDate =
          currentState.$store.state.booking.bookFormUpdate[index].startDate;
        let endDate =
          currentState.$store.state.booking.bookFormUpdate[index].endDate;
        let getRoom = currentState.$store.state.booking.bookFormUpdate[index];
        delete o.room;
        return {
          ...o,
          price: currentState.areaGetPricePerRoom({
            ...o,
            room: getRoom,
            startDate: startDate,
            endDate: endDate
          }),
          roomId: roomId,
          adminId: _get(parseJwtResult, "id", ""),
          startDate: startDate,
          endDate: endDate,
          updated_date: new Date()
        };
      });

      this.$bvModal.msgBoxConfirm("Confirm to submit?").then(async value => {
        if (value) {
          let updateObj = {};
          updateObj = { ...currentState.getBookIngItemSelected };
          delete updateObj.bookingItems;
          updateObj.status = currentState.bookingStatus;

          if (!currentState.bookingRemark) {
            updateObj.remark = "";
          } else {
            updateObj.remark = currentState.bookingRemark;
          }

          if (!currentState.changedBy) {
            updateObj.changedBy = "";
          } else {
            updateObj.changedBy = currentState.changedBy;
          }

          updateObj.maintenanceCost =
            currentState.bookingMaintenanceCostCalculate;
          updateObj.summary = currentState.bookingSummaryPriceCalculate;
          updateObj.depositPrice = currentState.bookingDepositCalculate;
          updateObj.isRequireAccessory =
            currentState.getBookIngItemSelected.isRequireAccessory;
          updateObj.adminId = parseJwtResult.id;
          updateObj.status = this.bookingStatus;
          updateObj.updated_date = new Date();
          updateObj.approved_date = new Date();

          let updateHistory = "";
          let getUpdateHistory = _get(
            currentState,
            "getBookIngItemSelected.updatedHistory",
            ""
          );

          let storeBookingItem = [];
          for (const item of currentState.updateBookingItemObj) {
            const filterRoomName = _filter(
              currentState.form,
              e => e.room.id === item.roomId
            );
            if (filterRoomName.length > 0) {
              let getRoomName = _get(filterRoomName, "[0].room.name", "");
              storeBookingItem.push({
                ...item,
                name: getRoomName
              });
            }
          }

          if (getUpdateHistory) {
            let parseJsonHistory = JSON.parse(getUpdateHistory);
            let copyObj = { ...updateObj };
            copyObj.updatedHistory = "";
            copyObj.updated_date = moment();

            let newhistory = {
              booking: copyObj,
              bookingItem: storeBookingItem
            };
            parseJsonHistory.push(newhistory);
            updateHistory = JSON.stringify(parseJsonHistory);
          } else {
            let copyObj = { ...updateObj };
            copyObj.updatedHistory = "";
            copyObj.updated_date = moment();
            let newhistory = JSON.stringify([
              {
                booking: copyObj,
                bookingItem: storeBookingItem
              }
            ]);
            updateHistory = newhistory;
          }

          updateObj.updatedHistory = updateHistory;
          let response = await currentState.$store.dispatch(
            "booking/settingBookingStatus",
            updateObj
          );
          if (response) {
            if (response.status === 204) {
              currentState.onUpdateBookingItem();
            } else {
              currentState.$bvModal.msgBoxOk("Update booking item failed");
            }
          } else {
            currentState.$bvModal.msgBoxOk("Update booking item failed");
          }
        }
      });
    },
    async onUpdateBookingItem() {
      let parseJwtResult = this.parseJwt;
      let currentState = this;
      let getBookingItems =
        _get(this.getBookIngItemSelected, "bookingItems", []) || [];
      this.updateBookingItemObj = _map(getBookingItems, (o, index) => {
        let roomId = currentState.$store.state.booking.bookFormUpdate[index].id;
        let startDate =
          currentState.$store.state.booking.bookFormUpdate[index].startDate;
        let endDate =
          currentState.$store.state.booking.bookFormUpdate[index].endDate;
        let getRoom = currentState.$store.state.booking.bookFormUpdate[index];
        delete o.room;
        return {
          ...o,
          price: currentState.areaGetPricePerRoom({
            ...o,
            room: getRoom,
            startDate: startDate,
            endDate: endDate
          }),
          roomId: roomId,
          adminId: _get(parseJwtResult, "id", ""),
          startDate: startDate,
          endDate: endDate,
          updated_date: new Date()
        };
      });
      let response = await this.$store.dispatch(
        "booking/updateBookingItem",
        this.updateBookingItemObj
      );
      if (response) {
        if (response[0].status === 204) {
          this.onsendMail();
        } else {
          this.$bvModal.msgBoxOk("Update booking item failed");
        }
      } else {
        this.$bvModal.msgBoxOk("Update booking item failed");
      }
    },
    async onsendMail() {
      let mailType = "";
      let sendMailObj = {};
      let currentState = this;

      switch (this.bookingStatus) {
        case "ปรับเปลี่ยนพื้นที่":
          mailType = "CONFIRMAREA";
          break;

        case "ชำระเงินเรียบร้อย":
          mailType = "PAID";
          break;

        case "สำเร็จ":
          mailType = "SUCCESS";
          sendMailObj.approved_date = new Date();
          break;

        case "อยู่ระหว่างการตรวจสอบ":
          mailType = "PENDING";
          sendMailObj.approved_date = new Date();
          break;

        case "อนุญาต คำขอใช้พื้นที่":
          mailType = "APPROVE";
          sendMailObj.approved_date = new Date();
          break;

        case "ไม่อนุญาต คำขอใช้พื้นที่":
          mailType = "REJECT";
          sendMailObj.approved_date = new Date();
          break;

        default:
          break;
      }
      sendMailObj.userId = this.getBookIngItemSelected.userId;
      sendMailObj.bookingItem = [...this.updateBookingItemObj];
      sendMailObj.mailType = mailType;

      let response = await this.$store.dispatch(
        "sendmail/sendMail",
        sendMailObj
      );
      if (response) {
        if (response.status === 200) {
          this.$bvModal.msgBoxOk("Update booking item success").then(() => {
            // TODO: clear calculate
            currentState.$router.push({ path: "/bookingmanagement" });
          });
        } else {
          this.$bvModal.msgBoxOk("Update booking item failed");
        }
      } else {
        this.$bvModal.msgBoxOk("Update booking item failed");
      }
    },
    onChangeAreaSelected(event, index) {
      if (event) {
        let obj = { room: event, form: this.form[index] };
        let isDuplicate = this.checkBookingOnAdminSelector(obj);
        this.$set(this.form, index, {
          ...this.form[index],
          isDuplicate: isDuplicate
        });

        if (!isDuplicate) {
          let obgFormatForm = [...this.$store.state.booking.bookFormUpdate];
          obgFormatForm[index] = { ...this.form[index].room };
          obgFormatForm[index].endDate = moment(
            this.form[index].endDate
          ).format("YYYY-MM-DD");
          obgFormatForm[index].startDate = moment(
            this.form[index].startDate
          ).format("YYYY-MM-DD");

          this.$store.dispatch(
            "booking/setUpdateBookingFormUpdate",
            obgFormatForm
          );
        }
      }
    },
    onChangeDateSelected(index) {
      let obj = { room: this.form[index].room, form: this.form[index] };
      let isDuplicate = this.checkBookingOnAdminSelector(obj);
      this.$set(this.form, index, {
        ...this.form[index],
        isDuplicate: isDuplicate
      });

      if (!isDuplicate) {
        let obgFormatForm = [...this.$store.state.booking.bookFormUpdate];
        obgFormatForm[index] = { ...this.form[index].room };
        obgFormatForm[index].endDate = moment(this.form[index].endDate).format(
          "YYYY-MM-DD"
        );
        obgFormatForm[index].startDate = moment(
          this.form[index].startDate
        ).format("YYYY-MM-DD");

        this.$store.dispatch(
          "booking/setUpdateBookingFormUpdate",
          obgFormatForm
        );
      }
    },
    onResetForm(index) {
      if (
        this.$store.state.booking.bookingItemSelectedTemp.bookingItems.length >
        0
      ) {
        let temp = this.$store.state.booking.bookingItemSelectedTemp
          .bookingItems[index];

        let resetFormIndex = {
          startDate: new Date(temp.startDate),
          endDate: new Date(temp.endDate),
          room: { ...temp.room },
          isDuplicate: false
        };

        this.$set(this.form, index, resetFormIndex);

        let obgFormatForm = [...this.$store.state.booking.bookFormUpdate];
        obgFormatForm[index] = { ...temp.room };
        obgFormatForm[index].startDate = moment(temp.startDate).format(
          "YYYY-MM-DD"
        );
        obgFormatForm[index].endDate = moment(temp.endDate).format(
          "YYYY-MM-DD"
        );
        this.$store.dispatch(
          "booking/setUpdateBookingFormUpdate",
          obgFormatForm
        );
      }
    },
    onRemoveBooking(index) {
      this.$bvModal
        .msgBoxConfirm("Are you sure to delete this booking item?")
        .then(value => {
          if (value) {
            this.$store.dispatch("booking/setRemoveBookingByIndex", index);
          }
        });
    },
    onSubmit() {
      this.$bvModal.msgBoxConfirm("Confirm to submit?").then(value => {
        if (value) {
          // this.$store.dispatch("booking/updateBookingItem", index);
        }
      });
    },
    onDepositCalculateUpdate(val) {
      this.$store.dispatch("booking/onDepositCalculateUpdate", val);
      return val;
    },
    onMaintenanceCostCalculateUpdate(val) {
      this.$store.dispatch("booking/onMaintenanceCostCalculateUpdate", val);
      return val;
    },
    onSummaryPriceCalculateUpdate(val) {
      this.$store.dispatch("booking/onSummaryPriceCalculateUpdate", val);
      return val;
    },
    onGetHistory() {
      let geUpdateHistory = _get(
        this.$store.state.booking,
        "bookingItemSelected.updatedHistory",
        ""
      );
      if (geUpdateHistory) {
        let isParse = this.isParseJson(geUpdateHistory);
        if (isParse) {
          return JSON.parse(geUpdateHistory);
        }
      }
      return [];
    },
    isParseJson(param) {
      try {
        JSON.parse(param);
      } catch (e) {
        return false;
      }
      return true;
    }
  }
};
</script>
